<template>
  <div id="app" ref="app" class="body-m">
    <!-- <Notification /> -->
    <slot />
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const route = useRoute()

useHead({
  link: [
    {
      rel: 'canonical',
      href: config.public.site.url + route.path,
    },
  ],
})
</script>

<style lang="scss">
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  @apply bg-white font-serif text-black h-full;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

@media print {
  .no-print {
    display: none !important;
  }
}
</style>
